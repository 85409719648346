import { useEffect } from 'react'
import NotFound from '../views/NotFound'
import { useRouter } from 'next/router'
import { languageList, useTranslation } from '@pancakeswap/localization'
import { Language } from '@pancakeswap/uikit'

const NotFoundPage = () => {
  const router = useRouter()
  const { setLanguage } = useTranslation()
  const jaLang: Language = languageList[1]
  const enLang: Language = languageList[0]
  const asPath = router.asPath
  useEffect(() => {
    if (asPath.includes('ja')) {
      router.replace(asPath.replace('/ja', '/'))
      setLanguage(jaLang)
    } else if (asPath.includes('en')) {
      router.replace(asPath.replace('/en', '/'))
      setLanguage(enLang)
    }
  }, [])
  if (!asPath.includes('ja') && !asPath.includes('en')) {
    return <NotFound />
  }
}

NotFoundPage.chains = []

export default NotFoundPage
